import React from 'react'
import errImg from '../images/exclamation_red.png';
import "../css/landingpage.css";

export default function Error() {
  return (
    <div className='failureDiv'>
        <img src={errImg} alt="" className="failureImg" />
        <p className="failureMsg">Oops!! Something went wrong. Please try again!</p>
    </div>
  )
}
