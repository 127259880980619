import React, { useEffect, useState } from 'react'
// import {
//     importAllImages
// } from './../Helper';
import { useSearchParams } from 'react-router-dom'
import '../css/Lead.css'
import { getProjectDetails, isUidExist } from './../Firebase';
import Spinner from './Spinner';
import InvalidURL from './InvalidUrl';
import LeadForm from './LeadForm';
import * as States from './States'

export default function Form() {

 

    const [state, setState] = useState(null);
    const [project, setProject] = useState("");
    const [leadCategory, setLeadCategory] = useState("");
    const [logo,setLogo]=useState();

    const [params] = useSearchParams();
    const hash = params.get('uid').split('=')[0];
    const projectId = hash.substring(0, 6);
    const uid = hash.substring(6, hash.length);
    // const images = importAllImages(require.context('./../images', false, /\.(png|jpe?g|svg)$/));

    useEffect(() => {
        isUidExist(uid, (isValidAuid) => {
            if(isValidAuid){
                if (state === null) {
            
                    getProjectDetails(projectId, 'DB01', function (res) {
                        setState(States.WAITING);
          
                        
                        if (res.status === States.FAILURE) {
                            setState(States.INVALID);
         
                        } else {
                            if (res.isOnHold === true) {
                                setState(States.ONHOLD);
                            }
                            else {
        
                                setProject(res.projectName);
                                setLeadCategory(res.leadCategory);
                                setLogo(res.logo);
                                setState(States.INIT);
        
                            }
                        }
                    });
                }
            }
            else{
                setState(States.INVALID)
            }
        })
   
    }, []);

    
    if (state === States.INVALID || state === States.ERROR) {
        return (
            <InvalidURL />
        )
    } else if (state === States.ONHOLD) {
        return (
            <div className="onhold">
                <img src={logo} alt="" className="projlogo" />
                <p className="onholtext">Currently we are on hold please visit us after some time!</p>
            </div>
        )
    } else if (state === States.INIT) {
        return (
            <div className="product d-flex flex-column">
                <div className="common">
                    <LeadForm projectName={project} aUid={uid} leadCategory={leadCategory}  projectLogo={logo} db={'DB01'} />
                </div>
            </div>
        );
    } else {
        return (
            <Spinner />
        )
    }
}
