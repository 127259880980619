import React from 'react'
import errorImg from '../images/exclamation_red.png'
import "../css/landingpage.css";


export default function InvalidUrl() {
  return (
    <div className='invalidUrl'>
        <img src={errorImg} alt="" className="errImg" />
        <p className="invalidurlmessage">Oops! we could not found this URL Valid..</p>
    </div>
  )
}