import React, { useState, useEffect } from "react";
import "../css/landingpage.css";
import aryoicon from "../formimages/Aryo.png";

function ProjectDetails(props) {
  const details = props.projectDetails;
  const [detailsArray, setDetailsArray] = useState([]);

  

  useEffect(() => {
    if (details) {
      const list = details.split(/\d+\./).filter(Boolean);
      setDetailsArray(list);
    }
  }, [details]);

  return (
   
    <div
    className="card-body p-md-4">
    <div className="px-md-2">
      <p>Features & Benefits</p>
      {detailsArray.map((det, index) => (
        <li key={index}>
          <span>
            <img
              src={aryoicon}
              alt="Bullet"
              className="bullet-image"
            />
          </span>
          <span className="text">
            {det.replace(/\\n\\n/g, "")}
          </span>
        </li>
      ))}
    </div>
  </div>              

                       
      
  );
}

export default ProjectDetails;
