import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getLinkCashBack, submitClick } from '../Firebase';
import { Timestamp } from 'firebase/firestore';
import Spinner from './Spinner';

export default function () {

    const [params] = useSearchParams();
    const [responseUrl, setResponseUrl] = useState(null);

    const id = params.get("id");
    const uid = params.get("uid");
    // console.log("Params :", params.get("campaign_id"), " source :", params.get("source"));
    // const source = params.get("source");
    // const URL = ("https://trk.opiclepxl.com/click?campaign_id=1487&pub_id=1993&p1={aryoId}&p2={aryoId}&source={aryoId}").replaceAll("{aryoId}", source);
    //get link for campaign_id
    // console.log("URL :", URL);
    const URL = "http://34.31.137.130:80/";
    useEffect(()=>{

        getLinkCashBack(id, (response) => {
            console.log(response)
            const subid = (Timestamp.now().seconds + Timestamp.now().nanoseconds);// uuidv4();
            console.log("subid ", subid, " ", response.url);
            const url = response.url.replaceAll('{gid}', subid);
            console.log("Url ", url);
            const click  = {
                'uid': uid,
                'product': response.name,
                'subId': subid
            }
            submitClick(click, (val) => {
                console.log("val ", val);
                if (val) {
                    window.open(url, "_self");
                }
            })
        })
        // const config = {
        //     method: 'get',
        //     url: URL + "getOfferLink/?id=" + id
        // }

        // axios(config)
        // .then(function (response) {
        //     console.log(response.data)
        //     // const subid = uuidv4();
        //     const subid = (Timestamp.now().seconds + Timestamp.now().nanoseconds);// uuidv4();
        //     console.log("subid ", subid, " ", response.data.url);
        //     const url = response.data.url.replaceAll('{gid}', subid);
        //     console.log("Url ", url);
        //     const click  = {
        //         'uid': uid,
        //         'product': response.data.name,
        //         'subId': subid
        //     }
        //     submitClick(click, (val) => {
        //         console.log("val ", val);
        //         if (val) {
        //             window.open(url, "_self");
        //         }
        //     })
            //put data in click_db
            // window.open(url, "_self");
            // setResponseUrl(response.data);
            // console.log(response.request.res)
        //    res(response.data);
    // })
    // .catch(function (error) {
    //     console.log(error);
    //     // res({'success': false})
    // });

    },[]);

    // console.log("final URL :", responseUrl)
    // if (responseUrl != null) {
    //     window.open(responseUrl, "_self");
    // }
  return (
    <div>
        <Spinner/>
        <h4>Please wait !!</h4>
    </div>
  )
}
