
import { Fragment } from 'react'
import spinner from '../spinner.gif'
import '../css/style.css'
const Spinner = () =>{
    return (
        <div className='Spinner' style={{ position: 'absolute',
        width: '30px',
        height: '30px',
        left: '45%',
        top: '45%',
       }}>
            <Fragment>
            <img className= "blueSpinner" alt='' src={spinner} style={{width: '40px', height: '40px'}}/>
            </Fragment>
        </div>
    )
}

export default Spinner;